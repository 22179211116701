<template>
  <div class="wrapper">
    <div class="header">
      <div class="logo"></div>
      <div class="offer">
        <h1 v-html="$t('header_site1')"></h1>
      </div>
    </div>
    <AppForm />
    <AppPlayer />
  </div>
</template>

<script>
import AppPlayer from "./AppPlayer.vue";
import AppForm from "./AppForm.vue";
export default {
  data() {
    return {
      deviceType: "",
      popupIsOpen: !1,
    };
  },
  created() {
    this.detectDeviceType();
  },
  methods: {
    closePopupEv(e) {
      this.popupIsOpen = e;
      if (this.popupIsOpen == !0) {
        document.documentElement.className = "no-overflow";
      } else {
        document.documentElement.classList.remove("no-overflow");
      }
    },
    detectDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();

      if (
        /mobile|android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        )
      ) {
        this.deviceType = "Mobile Device";
      } else if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
        this.deviceType = "Tablet";
      } else {
        this.deviceType = "Desktop";
      }
    },
  },
  components: {
    AppPlayer,
    AppForm,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,600;0,800;1,500;1,600;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,800;0,900;1,500;1,600;1,800;1,900&display=swap");

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="text"] {
  font-size: inherit;
}
body,
html {
  overflow: hidden;
  scroll-behavior: smooth;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
html.no-overflow body,
html.no-overflow {
  overflow: hidden;
}
#app {
  height: 100%;
  position: relative;
}
body {
  font-family: "Prompt", "Montserrat", sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  background-color: #004931;
  background: linear-gradient(180deg, #004931 0%, #003322 100%);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../assets/back1.jpg);
}
.wrapper {
  position: relative;
  color: #fff;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.wrapper.en {
  font-family: "Prompt", "Montserrat", sans-serif;
}

.header {
  z-index: 5;
  text-align: center;
}
.header .logo {
  position: absolute;
  width: 253.57px;
  height: 24px;
left: calc(50% - 253.57px / 2);
    top: 35px;
  z-index: 5;
}
.offer {
  position: absolute;
  width: 527px;
  height: 120px;
  left: calc(50% - 527px / 2);
  top: calc(50% - 120px / 2 - 265px);
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 13px;
}
.offer h1 {
  margin: 0;
  z-index: 5;
  font-family: "Prompt", "Montserrat", sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
}

.header h1 span,
span.yellow {
  color: #ffce06;
}
.logo {
  line-height: 0;
  background: url(../assets/logo.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: cover;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 864px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
br.desctop {
  display: block;
}
br.mobile,
br.tablet {
  display: none;
}

@media screen and (max-width: 959px) {
  .container {
    max-width: 560px;
  }
  .offer {
    width: 369px;
    height: 88px;
    left: calc(50% - 369px / 2);
  top: calc(50% - 88px/ 2 - 268px);
    gap: 6px;
  }
  .offer h1 {
font-size: 28px;
line-height: 32px;
  }

  .header .logo {
    width: 211px;
    height: 20px;
    left: calc(50% - 211px / 2);
    top: 48px;
  }

  body {
    background-image: url(../assets/back_1.jpg);
  }
  br.tablet {
    display: block;
  }
  br.mobile,
  br.desctop {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  body,
  html {
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    min-height: 1074px;
    height: 100%;
  }

  .container {
    max-width: 312px;
  }
  .container.fluid-container {
    max-width: 100%;
    position: static;
  }
  .header .logo {
    width: 169.05px;
    height: 16px;
    left: calc(50% - 169.05px / 2);
    top: 48px;
  }

  br.mobile {
    display: block !important;
  }
  br.tablet,
  br.desctop {
    display: none;
  }
  .offer {
    width: 312px;
    height: 76px;
    left: calc(50% - 312px / 2);
   top: 83px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;
  }
  .offer h1 {
    font-family: "Prompt", "Montserrat", sans-serif;
    font-style: italic;
    font-weight: 900;
    font-size: 22px;
    line-height: 26px;

    text-align: center;
  }

  body {
    background-image: url(../assets/back_2.jpg);
  }
}

@media screen and (max-width: 407px) {
  body,
  html {
    min-height: 980px;
    height: 100%;
  }
  .header .logo {
    top: 24px;
  }
  .container {
    max-width: 312px;
  }
  .container.fluid-container {
    max-width: 100%;
    position: static;
  }
  .logo {
    line-height: 0;
    width: 169.33px;
    height: 16px;
  }

  .offer {
top: 59px;
    gap: 9px;
  }
  .offer h1 {
    width: 340px;
    height: 48px;
    left: calc(50% - 340px / 2);
    top: 72px;
  }

  body {
    background-image: url(../assets/back_2_1.jpg);
  }
}
@media screen and (max-width: 599px) and (min-height: 552px) {
}
@media screen and (max-width: 359px) {
}
</style>
