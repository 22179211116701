<template>
  <div class="elements-top"></div>
  <div class="player1"></div>
  <div class="scroll"></div>
  <div class="gradient"></div>
</template>

<script>
export default {};
</script>

<style>
.player1 {
  content: "";
  position: absolute;
  width: 1224px;
  height: 802px;
    left: calc(50% - 1224px / 2 - -13px);
  bottom: -196px;

  background-image: url(../assets/player1.png);
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  z-index: 5;
}
.scroll {
  content: "";
  position: absolute;
  width: 476px;
  height: 673px;
  left: calc(50% - 476px / 2);
  bottom: -78px;

  background-image: url(../assets/scroll.png);
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  z-index: 5;
}
.elements-top {
  content: "";
  position: absolute;
width: 2192px;
height: 1109px;
left: calc(50% - 2192px / 2 + -5px);
    top: calc(50% - 1109px / 2 - -13px);
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  background-image: url(../assets/bg_elements.png);
  z-index: 1;
}
@keyframes floating {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
  50% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
}
@media screen and (max-width: 959px) {
  .player1 {
    width: 910px;
    height: 626px;
    left: calc(50% - 910px / 2);
    bottom: -74px;
    background-image: url(../assets/player1_1.png);
  }
  .elements-top {
    content: "";
    position: absolute;
    width: 768px;
    height: 375px;
      left: calc(50% - 765px / 2 + -3px);
    top: calc(50% - 461px / 2 - 194px);
    background-image: url(../assets/elements-top.png);
  }
 .scroll {
        bottom: -83px;
  }
}
@media screen and (max-width: 599px) {
  .player1 {
    background-image: url(../assets/player1_2.png);
    width: 537px;
    height: 533px;
left: calc(50% - 537px / 2 - -6px);
    top: calc(50% - 533px / 2 + -148px);
  }
  .scroll {
    width: 528px;
    height: 748px;
    left: calc(50% - 528px / 2);
        bottom: -173px;
  }
  .elements-top {
    width: 494px;
    height: 302px;
    left: calc(50% - 499px / 2 + 2px);
    top: -42px;
    background-image: url(../assets/elements-top-1.png);
  }
}
@media screen and (max-width: 407px) {
  .player1 {
    width: 462px;
    height: 458px;
left: calc(50% - 462px / 2 + 15px);
    top: calc(50% - 458px / 2 + -165px);
  }
  .scroll {
    width: 468px;
    height: 664px;
    left: calc(50% - 468px / 2);
   bottom: -104px;
  }
  .elements-top {
    width: 443px;
    height: 251px;
      left: calc(50% - 499px / 2 + 30px);
    top: -41px;
    background-image: url(../assets/elements-top-2.png);
  }
}
</style>
