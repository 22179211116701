import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import FlagIcons from "flag-icons";

const messages = {
  en: {
    by_phone: "By phone",
    by_email: "Via email",
    email: "E-Mail",
    phone: "Phone",
    enter_email: "Enter E-Mail",
    login: "Login",
    password: "Password",
    final_text:
      "By clicking the <span>«Signup»</span> button, you agree to the terms of the bonus promotion and the rules of the bookmaker.",
    registration: "Register",
    make_deposit: "Make a deposit",
    promocode: "Promocode",
    has_account: "Already have an account",
    add_promocode: "Add promocode",
    bonus_sport: "Sport",
    bonus_casino: "Casino",
    bonus_decline: "Refuse",
    bonus_title: "Choose your bonus",
    btn_text: "Register",
    header_site1:
      "<span>100% Welcome Bonus</span> <br/>& 50% VIP Cashback",
      header_site2:
      "<span>100% Welcome Bonus</span> <br/>& 50% VIP Cashback",
    final_text1:
      "By clicking the 'Register' button, you agree to the terms of the bonus promotion and the rules of the bookmaker.",
      subtitle:'Play the Best Live Baccarat',
  },
  th: {
    by_phone: "ทางโทรศัพท์",
    by_email: "ทางอีเมล์",
    phone: "โทรศัพท์",
    email: "อีเมล",
    enter_email: "กรอกที่อยู่อีเมล",
    login: "ลงชื่อเข้าใช้",
    password: "รหัสผ่าน",
    final_text:
      "โดยการกดที่ปุ่ม «ลงทะเบียน» จะถือว่าคุณยอมรับข้อกำหนดและเงื่อนไขของโปรโมชั่นโบนัสและกฎระเบียบของบริษัทรับพนัน",
    registration: "ลงทะเบียน",
    make_deposit: "ฝากเงิน",
    promocode: "รหัสโปรโมชั่น",
    has_account: "ฉันมีบัญชีแล้ว",
    add_promocode: "กรอกรหัสโปรโมชั่น",
    bonus_sport: "กีฬา",
    bonus_casino: "คาสิโน",
    bonus_decline: "ยกเลิก",
    bonus_title: "เลือกโบนัสของคุณ",
    btn_text: "ลงทะเบียน",
    header_site1:
      "โบนัสมูลค่า ฿35000 + 150 รอบหมุนฟรีสำหรับลูกค้าใหม่",
      header_site2:
      "โบนัสมูลค่า ฿35000 + 150 รอบหมุนฟรีสำหรับลูกค้าใหม่",
    final_text1:
    "โดยการกดที่ปุ่ม «ลงทะเบียน» จะถือว่าคุณยอมรับข้อกำหนดและเงื่อนไขของโปรโมชั่นโบนัสและกฎระเบียบของบริษัทรับพนัน",
    subtitle:'เล่นบาคาร่าสดที่ดีที่สุด',
  },


};
function detectLang() {
  let lang;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has("lang")) {
    lang = urlParams.get("lang");
  } else {
    let b =
      typeof window.serverData !== typeof undefined
        ? window.serverData.visitorData.languageCode
        : "th";
    switch (b) {
      case "en":
        lang = "en";
        break;
      case "th":
        lang = "th";
        break;

      default:
        lang = "th";
    }
  }
  return lang;
}
// 2. Create i18n instance with options
const i18n = createI18n({
  locale: detectLang(), // set locale
  fallbackLocale: "th", // set fallback locale
  allowComposition: true,
  messages,
  // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});
let app = createApp(App);
app.use(FlagIcons);
app.use(i18n);
app.mount("#app");
