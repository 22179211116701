<template>
  <AppLayout :class="this.$i18n.locale" />
</template>

<script>
import AppLayout from "./components/AppLayout.vue";

export default {
  name: "App",
  provide: {
  },
  components: {
    AppLayout,
  },
};
</script>

<style>
</style>
